const initialState = {
    token: null,
    id: null,
    role: null,
    error: null
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LOGIN_SUCCESS':
        return {
          ...state,
          token: action.payload.token,
          id: action.payload.id,
          role: action.payload.role,
          error: null
        };
        case 'LOGIN_OUT':
          return {
            ...state,
            token: null,
            id: null,
            role: null,
            error: null,
          };
      case 'LOGIN_FAILURE':
        return {
          ...state,
          error: "ошибка авторизации, неправильно введено имя или пароль",
        };
      case 'REGISTER_SUCCESS':
        return {
          ...state,
          error: null,
        };
      case 'REGISTER_FAILURE':
        return {
          ...state,
          error: action.payload,
        };
      case 'REGISTER_CLEAR':
        return initialState; // Сбрасываем состояние к изначальному
      default:
        return state;
    }
  };
  
  export default authReducer;