import React from 'react';
import { useDispatch } from 'react-redux';
import { btnReducerStop } from '../../../../redux/actions';
import stop from '../../../../img/stop.png'
import './BtnStop.css'

export default function BtnStop({imei}) {
  const dispatch = useDispatch();
  
    const handleClick = () => {
      dispatch(btnReducerStop(imei));
      alert(`Команда "СТОП" отправлена`);
    };
  
    return (
        <button className='BtnStop' onClick={handleClick}>
        <img  src={stop} alt="Result" />
        </button>
    );
}
