import  './DmStatus.css';

export default function DmStatus({status, islink}) {

    const checkBit = (num, bitPosition) => {
        return (num & (1 << bitPosition)) !== 0;
      };

    const forward=checkBit(status, 6);
    const revers=checkBit(status, 4);
    const nowater=checkBit(status, 3);

    let isDmStatus='';
    if (islink==='Offline') {isDmStatus= "Нет данных"} 
    else{
    if (forward && !nowater) isDmStatus="Движение вперед c водой";
    if (revers && !nowater) isDmStatus="Движение назад c водой";
    if (forward && nowater) isDmStatus="Движение вперед без воды";
    if (revers && nowater) isDmStatus="Движение назад без воды";
    if (!forward && !revers)  isDmStatus="Остановлена"}
    
    return(
        <>
        <div className='cardInfoStateDm'> 
            <span>{isDmStatus}</span>
        </div>
        </>
    )
}