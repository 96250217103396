import { useState ,useEffect} from 'react'
import { getDmImei, getDms, writesettings } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Button from "../../../UI/Button/Button";
import Modal from '../../../UI/Modal/Modal'
import '../DmConfig.css'
 
const DmPassport=() =>{
    // Получаем текущее значение параметра из Redux
    const parameter = useSelector((state) => state.dmimei.data[0]);
    const dispatch = useDispatch();
    const iduser = localStorage.getItem('iduser'); 

     useEffect(() => {
            setNameDm([parameter?.imei_name || '...']);
        }, [parameter]);
    const [nameDm, setNameDm] = useState()

    function SetNameDm() {
        const [modal, setModal] = useState(false)   
        const [inputvalue, setInputvalue] = useState();
        const [hasError, setHasError] = useState(false);
        function onClickNameOpen() {
            setInputvalue(nameDm);
            setModal(true);
            setHasError(false);
        }

        function onClickNameSave(inputvalue) {
            if (!inputvalue) {setHasError(true)} else {
            const num = 3;
            setInputvalue('');
            setModal(false);
            setHasError(false);
            dispatch(writesettings(parameter?.imei, inputvalue, num))
        .then(() => {
            // Обновляем данные после успешного сохранения
            return (dispatch(getDmImei(parameter?.imei)), dispatch(getDms(iduser)));
        })
        .then(() => {
            // Теперь parameter должно обновиться автоматически через useEffect
            console.log("Данные обновлены");
        })
        .catch((error) => {
            console.error("Ошибка при сохранении или обновлении данных: ", error);
        });
        }
    }
        return( 
            <div className='ParamInput'>
                <label>Название:</label>
                <Button  onClick={()=>onClickNameOpen()}>
                    <span className='buttonInput'>{nameDm}</span>
                </Button> 
                    <Modal open={modal}>
                         <div className='modalHeader'>
                            <h3>Введите новое значение</h3>
                            <svg  onClick={()=>setModal(false)} height="30" viewBox="0 0 200 200" width="30">    
                                <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                            </svg> 
                        </div>
                        <label className='labelInfo'>Название дождевальной машины:</label>
                        <h4 className='labelInfoQuet'>{hasError ? ("Передумали?") : ("Желаете установить "+`${inputvalue}`+'?')}</h4>
                        <div className='contentControl'>
                            <input type='text' className='input' value={inputvalue} onChange={e=>setInputvalue(e.target.value)}
                            style={{border: hasError ? '1px solid red' : null}} />
                         <div className='modalButton' >
                                    <Button onClick={() => setModal(false)}>Отмена</Button>
                                    <Button onClick={()=>onClickNameSave(inputvalue)}>Сохранить</Button>
                         </div>
                         </div>
                    </Modal>
             </div> 
            ) 
    }

    function SelectTypeDm() {
        useEffect(() => {
            setValue([(parameter?.type_dm_id*1-6)]);
        }, [parameter]);

        const arOptions = ['Секторная', 'Круговая'];
        const [value, setValue] = useState();//вставить приходящий параметр 
        const options = arOptions.map((text, index) => {
            return <option key={index} value={index}>{text}</option>;          
        });

        function onClickSaveTypeDm(e) {
             const num = 4;
             setValue(e.target.value);
            if(parameter?.type_dm_id!== (e.target.value*1+6)){
            dispatch(writesettings(parameter?.imei, (e.target.value*1+6), num))
            } 
        }
        return (
            <div className='ParamInput'>
                <label>Тип поливальной машины</label>
                <select className='DmSelect' value={value} 
                onChange={onClickSaveTypeDm}
                 >{options}</select> 
            </div> 
        )
     }

    // Используем useEffect для обновления состояния компонента при изменении Redux-состояния
    useEffect(() => {
        setPassportParam([parameter?.TimeSpeed100 || '...', parameter?.QSpeed100 || '...', parameter?.Ldm || '...', parameter?.S || '...']);
    }, [parameter]); // Зависимость от parameter

    const [passportParam, setPassportParam] = useState([]);
    const nameParam = [
        "Время прохождения полного круга при скорости 100%, ч",
        "Минимальная норма вылива при скорости 100%, мм",
        "Длинна машины, м",
        "Орошаемая площадь, га"
     ]

     function Parametr() {
        const [modal, setModal] = useState(false)
        const [param, setParam] = useState()
        const [inputvalue, setInputvalue] = useState();
        const [hasError, setHasError] = useState(false);
        function onClickWinOpen(name) {
            setInputvalue('');
            setModal(true);
            console.log(name);
            setParam(name);
            setHasError(false);
        }

        function onClickSave(num, inputvalue) {
        if (!inputvalue) {setHasError(true)} else {
        setInputvalue('');
        setModal(false);
        setHasError(false);
        let value=inputvalue.replace(',', '.');
            if(!isNaN(value) && value.trim() !== '') {// проверим что то что ввели это число
                inputvalue=value.toString();  
            } else {inputvalue=passportParam[num]} 
        dispatch(writesettings(parameter?.imei, inputvalue, 10+num))
        .then(() => {
            // Обновляем данные после успешного сохранения
            return (dispatch(getDmImei(parameter?.imei)), dispatch(getDms(iduser)));
        })
        .then(() => {
            // Теперь parameter должно обновиться автоматически через useEffect
            console.log("Данные обновлены");
        })
        .catch((error) => {
            console.error("Ошибка при сохранении или обновлении данных: ", error);
        });
        }
     }
        return (  
            nameParam.map((text, index)=>(
                <div className='ParamInput' key={index}> 
                <label>{text}</label> 
                <Button  onClick={()=>onClickWinOpen(text)}>
                <span className='buttonInput'>{passportParam[index]}</span>
                </Button> 
                {param===text &&
                <Modal open={modal}>     
                    <div className='modalHeader'>
                        <h3>Введите новое значение</h3>
                        <svg  onClick={()=>setModal(false)} height="30" viewBox="0 0 200 200" width="30">    
                            <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                        </svg> 
                    </div>
                    <div className='modalInfo'> 
                        <label className='labelInfo'>{text}</label>
                        <h4 className='labelInfoQuet'>{hasError ? ("Передумали?") : ("Желаете установить "+`${inputvalue}`+'?')}</h4>
                    </div>
                        <div className='contentControl'>
                        <input type='text' className='input' value={inputvalue} onChange={e=>setInputvalue(e.target.value)}
                        style={{border: hasError ? '1px solid red' : null}} />
                            <div className='modalButton' >
                                <Button onClick={() => setModal(false)}>Отмена</Button>
                                <Button onClick={()=>onClickSave(index,inputvalue)}>Сохранить</Button>
                            </div>
                        </div>
                </Modal>}
                </div>
            ))
        )
    }
    return(
        <div className='cardDm'>
            <h3>Общие настройки</h3>
            <SetNameDm />
            <SelectTypeDm />
            <div className='PassportSettings'><Parametr /></div> 
        </div>
    )
}
    export default DmPassport;