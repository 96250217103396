import { useState, useEffect } from 'react'
import './Checkbox.css';

export default function Checkbox({handleCheckboxChange, checked, id }) {
    return(    
           <div>        
            <input type='checkbox' className="ToggleCheckbox"
             checked={checked || false}
             onChange={handleCheckboxChange}
             id={id} />
            <label for={id}></label>
          </div>              
    )
}

