import {Link} from 'react-router-dom'
import './AboutProduct.css'
export default function AboutProduct() {

        return(
        <div className='mainContent'>
                <div className='headerContent'>
                    <h1>Автоматика для орошения</h1> 
                </div>
             
        </div>
    )
}