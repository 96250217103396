import  './AnglePosition.css';
import angle from '../../../img/angle.svg'

export default function AnglePosition({angleCurrent}) {
  
    return (
            <div className='angle'>   
                <img className='angleImg' src={angle} alt="Result" />
                <span>{angleCurrent+'\u00b0'}</span>
            </div>        
    )
}