import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './page/LoginPage/LoginPage'
import RegisterPage from './page/RegisterPage/RegisterPage';
import AboutProduct from './page/AboutProduct/AboutProduct';
import AdminDms from './page/AdminDms';
import UserDms from './page/UserDms';
import Imeis from './page/Imeis'; 
import Users from './page/Users';
import DmDetals from './components/DmDetals/DmDetals';
import Navbar from './components/UI/Navbar/Navbar';
import './App.css';

function App() {
  return (   
      <Router>
        <div className="App">
          <Navbar />
        <Routes >
          <Route exact path="/" element={<AboutProduct />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/admindms"  element={<AdminDms />} />
          <Route path="/userdms"  element={<UserDms />} />
          <Route path="/imeis"  element={<Imeis />} />
          <Route path="/users"  element={<Users />} />
          <Route path="/dmdetal/:id" element={<DmDetals />} />
        </Routes >
        </div>
      </Router>
  );
};

export default App;
