const initialState = {
  data: [],
  loading: false,
  error: null
};
  
  const dmReducer = (state = initialState, action) => {

    switch (action.type) {
      case 'FETCH_DMSDATA_REQUEST':
        return { ...state, loading: true, error: null };
      case 'FETCH_DMSDATA_SUCCESS':
        return { ...state, loading: false, data: action.payload };
      case 'FETCH_DMSDATA_FAILURE':
        return { ...state, loading: false, error: action.error };
      case 'FETCH_DMSDATA_CLEAR':
        return initialState; 
      default:
        return state;
    }
  };
  
  export default dmReducer;