import React, {useEffect,useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import { Map, YMaps, Circle, Polyline, Polygon } from 'react-yandex-maps';
import { useNavigate} from "react-router-dom";

    const CircleYandex = ()=>{

      const navigate = useNavigate();
      const data = useSelector((state) => state.dm?.data);
      const dataimei = useSelector((state) => state.dmimei?.data[0]);
      console.log("dataimei");
      console.log(dataimei);
      const OFFLINE_THRESHOLD_MINUTES = 5; // Порог оффлайна в минутах
      const browserTime = new Date();
      const checkBit = (num, bitPosition) => {
        return (num & (1 << bitPosition)) !== 0;
      };
        //вычисление координат через угол и расстояние
       const calcCoordinate = (latitude, longitude, radius, angle) => {
          // 111134.861111 это постоянная для перевода метров в угловые значения
          const x = latitude + Math.cos(angle * Math.PI / 180) * (radius / 111134.861111);
          // это расчет долготы с учетом зависимости от широты
          const y = longitude + Math.sin(angle * Math.PI / 180) * (radius / (Math.cos(latitude * Math.PI / 180) * 111321.377778));
          return [x , y];
        }       

      const handleClick = (circle) => {
        //e.preventDefault();
        //alert(`Кликнули на круг с ID: ${data?.[circle.id].imei}`);
        console.log("circleCLICK")
        console.log(data?.[circle.id].imei)
        navigate(`/dmdetal/${data?.[circle.id].imei}`);
        //localStorage.setItem('imei', 'item.imei')
      };
      // Подготовка массива с данными по связи
      const islink =data && data.map((row, rowIndex) => {
        let serverTime = new Date(row.param_last_date);
        let diffInMilliseconds = Math.abs(browserTime - serverTime);
        let diffInMinutes = Math.floor(diffInMilliseconds/(1000*60));
        let islink = diffInMinutes > OFFLINE_THRESHOLD_MINUTES ? 'Offline' : 'Online';
        return{id: rowIndex,islink : islink};
      });

      // Подготовка массива с данными для КРУГов
      const circles =data && data.map((row, rowIndex) => {

          let center = [(row?.PositionCShr)*1, (row?.PositionCDl)*1]; // Центр круга
          let radius = row?.Ldm*1; // Радиус круга в метрах (например, 5 км)
          const nowater=checkBit(row?.param4, 3);//включен режим "без воды"
          const colorCircle = islink[rowIndex].islink==='Online' ? (nowater ? ("rgba(37, 240, 14, 0.5)"):("rgba(40, 165, 237, 0.5)")) : ("#dedcdcb3"); 
          const colorOutline = islink[rowIndex].islink==='Online' ? (nowater ? ("rgba(35, 240, 12, 0.7)"):("rgba(22, 161, 242, 0.6)")) : ("#b8b4b4"); 
          let imei_name =  row?.imei_name;
          // на выходе имеем массив с данными для КРУГов
          return {center: center, radius: radius, fillColor: colorCircle,
             strokeColor: colorOutline, imei_name: imei_name, id: rowIndex};
      });
        console.log("circles")
        console.log(circles);

        // Подготовка массива с данными для Линии Текущего положения
        const polylines =data && data.map((row, rowIndex) => {
          // Здесь должна быть ваша логика вычисления значения
          
          let center = [(row.PositionCShr)*1, (row.PositionCDl)*1]; // Центр круга
          let radius = row.Ldm*1; // Радиус круга в метрах (например, 5 км)
          // Вычислим координаты точки на окружности (например, на 45 градусов)
          let angleInRadians = (row?.AngleCurrent * Math.PI) / 180; // Угол в радианах текущего положения
          let pointOnCircle = [
           center[0]*1 + (radius / 111000) * Math.cos(angleInRadians), // 1 градус широты ≈ 111 км
           center[1]*1 + (radius / (111000 * Math.cos((center[0] * Math.PI) / 180))) * Math.sin(angleInRadians), // Коррекция долготы
           ];

           let angleOffSetInRadians = (row?.OffsetNulN * Math.PI) / 180; // Угол в радианах угла смещения
           let pointOnCircleOffSet = [
            center[0]*1 + (radius / 111000) * Math.cos(angleOffSetInRadians), // 1 градус широты ≈ 111 км
            center[1]*1 + (radius / (111000 * Math.cos((center[0] * Math.PI) / 180))) * Math.sin(angleOffSetInRadians), // Коррекция долготы
            ];
           // на выходе имеем массив Линии Текущего положения всех машин
          return {center: center, pointOnCircle: pointOnCircle, pointOnCircleOffSet: pointOnCircleOffSet };
      });

      // Подготовка массива с данными для треугольника-стрелочки
        const polygons =data && data.map((row, rowIndex) => {
          //задаем размеры стрелочки
          const sizeMoveMarker = {
            angle: 8, 
            size: row?.Ldm*1 * 0.2
          }  
        const colortriangle = islink[rowIndex].islink==='Online' ? ("#ff0000") : ("#dedcdcb3");
        const forward=checkBit(row?.param4, 6);
        const revers=checkBit(row?.param4, 4);
          //определим куда будет смотреть стрелочка
        let arrowheadAngle = forward ? (row?.AngleCurrent*1+sizeMoveMarker.angle) : (
          revers ? (row?.AngleCurrent*1-sizeMoveMarker.angle) : (row?.AngleCurrent*1)
        );
        // получение координаты на конце 
        let trianglePoint1 = calcCoordinate(row?.PositionCShr*1, row?.PositionCDl*1, row?.Ldm*1, row?.AngleCurrent*1);
         // получение координаты наконечника стрелочки
         let trianglePoint2 = calcCoordinate(row?.PositionCShr*1, row?.PositionCDl*1, row?.Ldm*1-sizeMoveMarker.size/2, arrowheadAngle*1);
         // получение координаты последей координаты 
         let trianglePoint3 = calcCoordinate(row?.PositionCShr*1, row?.PositionCDl*1, row?.Ldm*1-sizeMoveMarker.size, row?.AngleCurrent*1);
        // Формируем массив с координатами треугольника
        let triangleCoordinates = [trianglePoint1, trianglePoint2, trianglePoint3, trianglePoint1];
        return {triangleCoordinates: triangleCoordinates, colortriangle: colortriangle};
    });

    const center=[(dataimei?.PositionCShr)*1, (dataimei?.PositionCDl)*1]; // Центр активного круга
  return (  
    <YMaps>
      <Map defaultState={{center, zoom: 13 }} width="100%" height="100%">
          {/* Отрисовка окружности - обводки текущей машины*/}
          <Circle 
          key={1000}
            geometry= {[[(dataimei?.PositionCShr)*1, (dataimei?.PositionCDl)*1],(dataimei?.Ldm*1+50)]}
            options={{
              draggable: false, // Окружность нельзя перетаскивать
              fillColor: "rgba(255, 255, 255, 0)", // Цвет заливки
              strokeColor: "rgba(255, 162, 3, 1)", // Цвет обводки
              strokeWidth: 5, // Ширина обводки
            }}
          />
              {/* Отображаем круги всех машин */}
        { circles.map((circle, index) => (
          <Circle
            key={index}
            geometry={[circle.center, circle.radius]}
            options={{
              draggable: false, // Окружность нельзя перетаскивать
              fillColor: circle.fillColor, // Цвет заливки
              strokeColor: circle.strokeColor || "rgba(0, 0, 255, 0.7)", // Цвет обводки
              strokeWidth: circle.strokeWidth || 2, // Ширина обводки
            }}
            modules={["geoObject.addon.hint"]} // Подключаем модуль для подсказки
          properties={{
            hintContent: circle.imei_name, // Текст подсказки
          }}
            onClick={() => handleClick(circle)} // Обработчик клика
          />
        ))}

          {/* Отображаем линию парковки от центра круга до точки на окружности */}
         { polylines.map((lines, index) => (
                <Polyline 
                   key={index}
                   geometry={[lines.center, lines.pointOnCircleOffSet]} 
                   options={{ 
                     strokeColor: '#e8fc00', // желтая линия 
                     strokeWidth: 3, // Ширина линии 
                   }} 
                 />
                ))}

            {/* Отображаем линию от центра круга до точки на окружности */}
         { polylines.map((lines, index) => (
                <Polyline 
                   key={index}
                   geometry={[lines.center, lines.pointOnCircle]} 
                   options={{ 
                     strokeColor: '#ff0000', // Красная линия 
                     strokeWidth: 3, // Ширина линии 
                   }} 
                 />
                ))}

            {/* Отображаем  точки - центра всех машин */}
        { circles.map((circle, index) => (
          <Circle
            key={index}
            geometry={[circle.center, circle.radius*0.05]}
            options={{
              draggable: false, // Окружность нельзя перетаскивать
              fillColor: '#ff0000', // Цвет заливки
              strokeColor: '#ff0000', // Цвет обводки
              strokeWidth: circle.strokeWidth || 2, // Ширина обводки
            }}
            onClick={() => handleClick(circle)} // Обработчик клика
          />
        ))}

          {/* Треугольник */}
          { polygons.map((triangles, index) => (
                 <Polygon 
                   key={index}
                   geometry={[triangles.triangleCoordinates]} 
                   options={{ 
                     fillColor: triangles.colortriangle, 
                     strokeColor: triangles.colortriangle, 
                     opacity: 0.5, 
                     strokeWidth: 3, 
                   }} 
                 /> 
                ))}
       </Map>
      </YMaps> 
  );
};   
export default CircleYandex;
