import {useState} from 'react';

import './Button.css'

export default function button({children, onClick, isActive}) {
   

    return(
        <button 
        className={isActive ? 'button clicked' : 'button'}
        onClick={onClick}
        >
        {children}
        </button>
    )
}