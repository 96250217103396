import {useSelector } from 'react-redux';
import { useState ,useEffect} from 'react'
import { Link } from 'react-router-dom';
import DmList from '../components/DmList/DmList';
import FooterNav from '../components/UI/FooterNav/FooterNav';

export default function AdminDms() {


    return(
        <>
             <DmList />
             <FooterNav />
        </>
    )
}
