import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getDmImei, writesettings } from '../../../../redux/actions';
import Checkbox from '../../../UI/Checkbox/Checkbox';
import Button from "../../../UI/Button/Button";
import Modal from '../../../UI/Modal/Modal'
import '../DmConfig.css'


export default function DmPressure() {
    // Получаем текущее значение параметра из Redux
    const parameter = useSelector((state) => state.dmimei.data[0]);

    const dispatch = useDispatch();
    useEffect(() => {
        setPressConf([parameter?.PMax || '...', parameter?.VaterUstP || '...', parameter?.TimeUst1 || '...']);
    }, [parameter]);

    const [pressConf, setPressConf] = useState([]);
    const nameParam = [
    "Диапазон датчика давления, Бар",
    "Остановить ДМ при давлении ниже, Бар",
    "Таймер сухого хода, мин",
    ]

    function PressParametr() {
        const [modal, setModal] = useState(false)
        const [param, setParam] = useState()
        const [inputvalue, setInputvalue] = useState();
        const [hasError, setHasError] = useState(false)
        function onClickWinOpen(name) {
            setInputvalue('');
            setModal(true);
            setHasError(false);
            console.log(name);
            setParam(name);
        }
        function onClickSave(num, inputvalue) {  
            if (!inputvalue) {setHasError(true)} else { 
            setInputvalue('');
            setModal(false); 
            setHasError(false);
            console.log('save '+'номер '+ num +' значение ' + inputvalue);
            let value=inputvalue.replace(',', '.');
            if(!isNaN(value) && value.trim() !== '') {// проверим что то что ввели это число
                inputvalue=Math.round(value*1);  
            } else {inputvalue=pressConf[num]} 
            dispatch(writesettings(parameter?.imei, inputvalue, 30+num))
            .then(() => {
                // Обновляем данные после успешного сохранения
                return dispatch(getDmImei(parameter?.imei));
            })
            .then(() => {
                // Теперь parameter должно обновиться автоматически через useEffect
                console.log("Данные обновлены");
            })
            .catch((error) => {
                console.error("Ошибка при сохранении или обновлении данных: ", error);
            });
                //запишем уставку давления в единицах АЦП
            if (num===1) { 
                const minADC = 800;  // Минимальное значение АЦП (соответствует 4 мА)
                const maxADC = 3000; // Максимальное значение АЦП (соответствует 20 мА)
                const Pmin = 0;      // Минимальное давление (4 мА = 0 Бар)
                const ustADC = minADC + ((inputvalue-Pmin)/(parameter?.PMax-Pmin))*(maxADC-minADC);
                console.log("ADC: "+ustADC);
                inputvalue = ustADC;
                dispatch(writesettings(parameter?.imei, inputvalue, 35+num))
                .then(() => {
                    // Обновляем данные после успешного сохранения
                    return dispatch(getDmImei(parameter?.imei));
                })
                .then(() => {
                    // Теперь parameter должно обновиться автоматически через useEffect
                    console.log("Данные обновлены");
                })
                .catch((error) => {
                    console.error("Ошибка при сохранении или обновлении данных: ", error);
                });
            }
            if(num===2) {
                dispatch(writesettings(parameter?.imei, inputvalue*60, 35+num))
                .then(() => {
                    // Обновляем данные после успешного сохранения
                    return dispatch(getDmImei(parameter?.imei));
                })
                .then(() => {
                    // Теперь parameter должно обновиться автоматически через useEffect
                    console.log("Данные обновлены");
                })
                .catch((error) => {
                    console.error("Ошибка при сохранении или обновлении данных: ", error);
                });
            }
          }
        } 
        return (  
            nameParam.map((text, index)=>(
            
                <div className='ParamInput' key={index}> 
                <label>{text}</label> 
                <Button  onClick={()=>onClickWinOpen(text)}>
                <span className='buttonInput'>{pressConf[index]}</span>
                </Button> 
                {param===text &&
                <Modal open={modal}>     
                    <div className='modalHeader'>
                        <h3>Введите новое значение</h3>
                        <svg  onClick={()=>setModal(false)} height="30" viewBox="0 0 200 200" width="30">    
                            <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                        </svg> 
                    </div>
                    <div className='modalInfo'> 
                        <label className='labelInfo'>{text}</label>
                        <h4 className='labelInfoQuet'>{hasError ? ("Передумали?") : ("Желаете установить "+`${inputvalue}`+'?')}</h4>
                    </div>
                        <div className='contentControl'>
                        <input type='text' className='input' value={inputvalue} onChange={e=>setInputvalue(e.target.value)}
                        style={{border: hasError ? '1px solid red' : null}} />
                            <div className='modalButton' >
                                <Button onClick={() => setModal(false)}>Отмена</Button>
                                <Button onClick={()=>onClickSave(index,inputvalue)}>Сохранить</Button>
                            </div>
                        </div>
                </Modal>}
                </div>
            ))
        )  
    }

    const [mode, setMode] = useState();
    const [checked, setChecked] = useState();
    useEffect(() => {
        if (parameter?.PUse===1) { //используем
            (setMode(false));
            (setChecked(false));
        }

        if (parameter?.PUse===0) { //не используем
            (setMode(true));
            (setChecked(true));
        }
        }, [parameter]);   
    
     function handleCheckboxChange(event) {
        let inputvalue=5;
        inputvalue = 1-parameter?.PUse;
        const num = 5;
         setChecked(event.target.checked);

        dispatch(writesettings(parameter?.imei, inputvalue, num))
        .then(() => {
            // Обновляем данные после успешного сохранения
            return dispatch(getDmImei(parameter?.imei));
        })
        .then(() => {
            // Теперь parameter должно обновиться автоматически через useEffect
            console.log("Данные обновлены");
        })
        .catch((error) => {
            console.error("Ошибка при сохранении или обновлении данных: ", error);
        });
        if (num===5) { //запишем флаг в команды 

        dispatch(writesettings(parameter?.imei, inputvalue+1, num+30))
        .then(() => {
            // Обновляем данные после успешного сохранения
            return dispatch(getDmImei(parameter?.imei));
        })
        .then(() => {
            // Теперь parameter должно обновиться автоматически через useEffect
            console.log("Данные обновлены");
        })
        .catch((error) => {
            console.error("Ошибка при сохранении или обновлении данных: ", error);
        });
         }
     
    
    }
    return(
        <div className='cardDm'> 
        <h3>Давление</h3>
                <div className='ParamInput'>
                    <label>Использовать датчик давления</label>   
                    <div className='checkboxPress'>
                        <Checkbox handleCheckboxChange={handleCheckboxChange}  checked={checked} id="checkbox2" /> 
                    </div>
                </div>
                <PressParametr />
        </div>
    )
}