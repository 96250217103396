const initialState = {
  data: [],
  loading: false,
  error: null
};
  
  const dmimeiReducer = (state = initialState, action) => {

    switch (action.type) {
      case 'FETCH_DMIMEIDATA_REQUEST':
        return { ...state, loading: true, error: null };
      case 'FETCH_DMIMEIDATA_SUCCESS':
        return { ...state, loading: false, data: action.payload };
      case 'FETCH_DMIMEIDATA_FAILURE':
        return { ...state, loading: false, error: action.error };
      case 'FETCH_DMIMEIDATA_CLEAR':
        return initialState;
      default:
        return state;
    }
  };
  
  export default dmimeiReducer;