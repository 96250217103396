import React, {useEffect,useState} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDms } from '../../redux/actions';
import DmSummary from '../DmSummary/DmSummary';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';
import magnifier from "../../img/magnifier.svg";
import plus from "../../img/plus.png";
import './DmList.css';

const DmList = ()=>{
  const navigate = useNavigate();
  const token = localStorage.getItem('token'); // Предположим, что токен хранится в localStorage
  const [input,setInput] =useState('');
  const dispatch = useDispatch();
  const parametr =useSelector((state) => state.userdata?.data);
  
  useEffect(() => {
            if (token === null) { navigate('/'); // Перенаправление на общую страницу
            }
          }, [token, navigate]);

  useEffect(() => {
    const fetchGetDms = async () => {
        try { await dispatch(getDms());
        } catch (error) { console.error('Ошибка при получении данных:', error);}
    };
    const intervalId = setInterval(fetchGetDms, 1000); 
    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);

    const datain = useSelector((state) => state);
    console.log(datain);
    console.log('загрузка dmlist');
    const data = useSelector((state) => state.dm?.data);
    console.log("data");  
    console.log(data);  
       
        const [modal, setModal] = useState(false);
          const [inputvalue, setInputvalue] = useState();
          function onClickWinOpen() {
              setInputvalue('');
              setModal(true);
          }
          function onClickSave () {
            setModal(false);
          }
        return(
          <>
            <div className='DmList'> 
              <div className='DmListHeader'> 
                <div className='DmListName'> 
                  <h2>Оборудование<span>{data.length}</span></h2> 
                  <div className='DmListAdd'>
                      <button className="DmlistBtn" onClick={()=>onClickWinOpen()} > 
                          <img src={plus} alt="Result" className='DmListQuestImgAdd' />
                      {parametr?.role === 1 ? 'Создать ДМ' : 'Добавить'}</button>
                  </div>
                </div>
                <div className='DmListQuest'> 
                  <input type='text' className='DmListInput' placeholder='Введите название для поиска'
                    value={input}
                    onChange={(e)=>setInput(e.target.value)}
                  /> 
                  <img src={magnifier} alt="Result" className='DmListQuestImgMag'/>
                </div>
              </div> 
                      <div className='DmListMain'>
                        {data && data.filter((item) =>
                          item.imei_name.toLowerCase().includes(input.toLowerCase())
                        ).map(item=>{
                          return(<DmSummary item={item} key={item.id} />)})}
                      </div>
            </div> 
            <Modal open={modal}>     
                    <div className='modalHeader'>
                        <h3>Введите IMEI</h3>
                        <svg  onClick={()=>setModal(false)} height="30" viewBox="0 0 200 200" width="30">    
                            <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                        </svg> 
                    </div>
                    <div className='modalInfo'> 
                        <label className='labelInfo'></label>
                        <h4 className='labelInfoQuet'>
                        Желаете {parametr?.role === 1 ? 'создать новую ДМ' : 'добавить ДМ'} {inputvalue} ? </h4>
                    </div>
                        <div className='contentControl'>
                        <input type='text' className='input' value={inputvalue} onChange={e=>setInputvalue(e.target.value)} />
                            <div className='modalButton' >
                                <Button onClick={() => setModal(false)}>Отмена</Button>
                                <Button onClick={()=>onClickSave(inputvalue)}>Сохранить</Button>
                            </div>
                        </div>
                </Modal>         
          </>
        )
    }
     export default DmList;