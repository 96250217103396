import './DmLink.css';

export default function DmLink ({islink}) {
    return(
            <div className={islink}> 
                <span className='linkText'>{islink}</span>
            </div>
    )
}


