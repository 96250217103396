import ImeiList from '../components/ImeiList/ImeiList';
import FooterNav from '../components/UI/FooterNav/FooterNav';

export default function Imeis() {

    return(
        <>
            <ImeiList />
            <FooterNav />
        </>
    )
}