import './DmGun.css';
import gun from '../../../img/gun.png';

export default function DmGun({status, islink} ) {

    const checkBit = (num, bitPosition) => {
        return (num & (1 << bitPosition)) !== 0;
      };

    const gunRun = checkBit(status, 5)
    return(
        <div className='gunInfo'>
        {islink==='Online' && gunRun ? (
            <img  src={gun} alt="Result" />
        ) : (<p>  </p>)}
        </div>

    )
}