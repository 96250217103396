import {useSelector } from 'react-redux';
import { useState ,useEffect} from 'react';
import DmLink from '../UI/DmLink/DmLink';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';
import sbros from '../../img/sbros.png'; 
import delet from '../../img/udalit.png';
import './ImeiSummary.css';

export default function ImeiSummary({item}) {

    const [imeiSummaryInfo, setImeiSummaryInfo ] = useState({
        imei: '...',
        imei_name: '...',
        param_last_date: 0,
        username: '...',
    });
    
    //проверка входных данных
    useEffect(() => {
        setImeiSummaryInfo({
        imei: item?.imei || '...',    
        imei_name: item?.imei_name || '...',
        param_last_date: item?.param_last_date || 0,
        username: item?.username || '...',
        })
    },[item]);

    const OFFLINE_THRESHOLD_MINUTES = 5; // Порог оффлайна в минутах
    const serverTime = new Date(imeiSummaryInfo.param_last_date);
    const browserTime = new Date();
    const diffInMilliseconds = Math.abs(browserTime - serverTime);
    const diffInMinutes = Math.floor(diffInMilliseconds/(1000*60));
    const islink = diffInMinutes > OFFLINE_THRESHOLD_MINUTES ? 'Offline' : 'Online';

    const [action, setAction] = useState('');
    const [modal, setModal] = useState(false)

    function onClickWinOpen(value) {
        setAction(value);
        setModal(true);
    }
    function onClickActionSave() {
        action === 'delet' ? console.log('delet imei') :  console.log('Настройки по умолчанию');
        setModal(false);
    }

    return(
        <>
        <div className='imeiCard'>
            <div className='imeiCardHeader'>
                <strong>{imeiSummaryInfo.imei}</strong>
                {/* {islink==='Offline' ? (imeiSummaryInfo.param_last_date) : (<DmLink islink={islink} />) } */}
                <DmLink islink={islink} />
            </div>
            <div className='imeiCardBody'>
                <div className='imeiCardBodyInfo'>
                    <div className='imeiInfoTitle'>
                    Владелец:<span> {imeiSummaryInfo.username}</span>
                    </div>
                    <div className='imeiInfoTitle'>
                        Имя:<span> {imeiSummaryInfo.imei_name}</span>
                    </div>
                </div>
                <div className=' imeiCardBodyBtn'>
                    <button onClick={()=>onClickWinOpen('reset')} className='imeiBtn'>
                        <img src={sbros} alt="Result" /> 
                    </button>
                    <button onClick={()=>onClickWinOpen('delet')} className='imeiBtn'>
                        <img src={delet} alt="Result" /> 
                    </button>
                </div>
            </div>
        </div>  

        <Modal open={modal}>
             <div className='modalHeader'>
                <h3>Подтвердите действие</h3>
                <svg  onClick={()=>setModal(false)} height="30" viewBox="0 0 200 200" width="30">    
                    <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                </svg> 
            </div>
            <label className='labelInfo'>Вы точно желаете {action==='reset' ? ' сбросить настройки?' : ' удалить IMEI?' } </label>
            <h4 className='labelInfoQuet'>{imeiSummaryInfo.imei}</h4>
            <div className='contentControl'>
             <div className='modalButton' >
                        <Button onClick={() => setModal(false)}>Отмена</Button>
                        <Button onClick={onClickActionSave}>Да</Button>
             </div>
             </div>
        </Modal>
    </>
    )
}