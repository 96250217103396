import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getDmImei,writesettings } from '../../../../redux/actions';
import Button from "../../../UI/Button/Button";
import Modal from '../../../UI/Modal/Modal'
import time from '../../../../img/time.svg';
import './BtnTime.css'

export default function BtnTime() {
    const parameter = useSelector((state) => state.dmimei?.data[0]);
    const dispatch = useDispatch();

    // Используем useEffect для обновления состояния компонента при изменении Redux-состояния
    useEffect(() => {
        setTimeMove(parameter?.TimeMove);
    }, [parameter]); // Зависимость от parameter

    const [modal, setModal] = useState(false)
    const [timeMove, setTimeMove] = useState()
    const [inputvalue, setInputvalue] = useState();
    const [hasError, setHasError] = useState(false);

    function onClickWinOpen() {
        setInputvalue('');
        setModal(true);
        setHasError(false);
        }

    function onClickSave(inputvalue) {
        if (!inputvalue) {setHasError(true)} else {
        setInputvalue('');
        setTimeMove(inputvalue);
        setModal(false);  
        setHasError(false);
        let timemove=(inputvalue.replace(',', '.')*1).toFixed(2);
        if(timemove<(parameter?.TimeSpeed100)*1){timemove=parameter?.TimeSpeed100};
        const speed=((parameter?.TimeSpeed100/timemove)*100).toFixed(2);
        const qnorma=((timemove*parameter?.QSpeed100)/parameter?.TimeSpeed100).toFixed(2);
        dispatch(writesettings(parameter?.imei, speed, 0))
        dispatch(writesettings(parameter?.imei, timemove, 2))
        dispatch(writesettings(parameter?.imei, qnorma, 1))
        dispatch(writesettings(parameter?.imei, Math.round(speed).toString(), 6))
        .then(() => {dispatch(getDmImei(parameter?.imei))})
        .then(() => {console.log("Данные обновлены")})
        .catch((error) => {console.error("Ошибка при сохранении или обновлении данных: ", error);});
      }
    }

    return(
        <div className='CalcBtn'>
             <button className='CalcTime' onClick={onClickWinOpen}> 
             <img  src={time} alt="Result" />
             {timeMove} ч</button>
             <Modal open={modal}>
            <div className='modalHeader'>
                        <h3>Введите новое значение</h3>
                        <svg  onClick={()=>setModal(false)} height="30" viewBox="0 0 200 200" width="30">    
                            <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                        </svg> 
                    </div>
                    <div className='modalInfo'> 
                        <label className='labelInfo'>Время хода, ч</label>
                        <h4 className='labelInfoQuet'>{hasError ? ("Передумали?") : ("Изволите установить "+`${inputvalue}`+'?')}</h4>
                    </div>
                        <div className='contentControl'>
                        <input type='text' className='input' value={inputvalue} onChange={e=>setInputvalue(e.target.value)}
                        style={{border: hasError ? '1px solid red' : null}} />
                            <div className='modalButton' >
                                <Button onClick={() => setModal(false)}>Отмена</Button>
                                <Button onClick={()=>onClickSave(inputvalue)}>Сохранить</Button>
                            </div>
                        </div>                     
            </Modal> 
        </div>
    )
}