const initialState = {
    id: null,
    role: null,
    username: null,
    loading: false,
    error: null
  };
  
  const userReducer = (state = initialState, action) => {

    switch (action.type) {
      case 'LOGIN_SUCCESS':
        return {
          ...state,
          id: action.payload.id,
          role: action.payload.role,
          username: action.payload.username,
          loading: true, 
          error: null
        };
      case 'FETCH_USERDATA_REQUEST':
        return { 
          ...state, 
          loading: true, 
          error: null };
      case 'FETCH_USERDATA_SUCCESS':
        console.log("action");
        console.log(action?.payload);
        return { 
          ...state, 
          id: action.payload.id,
          role: action.payload.role,
          username: action.payload.username,
          loading: false, 
          error: null };
      case 'FETCH_USERDATA_FAILURE':
        return { ...state, loading: false, error: action.error };
        case 'FETCH_USERDATA_CLEAR':
          return initialState; // Сбрасываем состояние к изначальному
      default:
        return state;
    }
  };
  
  export default userReducer;