import React, { useState,useEffect } from 'react';
import {Link} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, getUserData } from '../../redux/actions';
import './LoginPage.css'
import { useNavigate } from 'react-router-dom';

export default function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const error = useSelector((state) => state.auth.error);
  const role = useSelector((state) => state.userdata?.role);
  const token = useSelector((state) => state.auth.token) || localStorage.getItem('token');

   useEffect(() => {
    const token = localStorage.getItem('token');
     if (token) {
       dispatch({ type: 'LOGIN_SUCCESS', payload: { token } });
     };
   }, [dispatch,token]);

   useEffect(() => {
    if (token && role === 1) {
      navigate('/admindms');
    } else if (token && role === 2) {
      navigate('/userdms');
    } else if (error) {
      dispatch({ type: 'REGISTER_CLEAR' });
      navigate('/');
    }
  }, [token, role, navigate, error]);

  const handleClick  = (e) => {
    e.preventDefault();
    dispatch(loginUser({ username, password }));
  };

  return (
    <form className='loginPage'> 
    <div className='loginCard'>
      <div className='loginCardTitle'>
        <h2 >Авторизация</h2>
      </div> 
      
      <div className='loginCardRoot'> 
        <label className='loginCardLable'>Логин:</label>
        <input type='text'className='loginCardInput' 
        value={username}
        onChange={(e)=>setUsername(e.target.value)}></input> 
      </div>
      <div className='loginCardPassword'> 
        <label className='loginCardLable'>Пароль:</label>
        <input type='text' className='loginCardInput'
        value={password}
        onChange={(e)=>setPassword(e.target.value)}></input> 
      </div>
      <div className='loginCardButton'> 
        <Link className='loginCardLink' to="/register">Регистрация</Link>
        <button className='loginCardBtnInp'
        onClick={handleClick }>Вход</button>   
      </div>
        {error && <p>{error}</p>}     
    </div>
    </form>
      );
};
