import React from 'react';
import { useDispatch } from 'react-redux';
import { btnReducerRevers } from '../../../../redux/actions';
import rotationRevers from '../../../../img/revers.png'
import './BtnRevers.css'

export default function BtnRevers({islink, state, imei}) {

    const dispatch = useDispatch();
    const checkBit = (num, bitPosition) => {
      return (num & (1 << bitPosition)) !== 0;
    };

    const revers=checkBit(state, 4);
  
    const handleClick = () => {
      dispatch(btnReducerRevers(imei));
      alert(`Команда "REVERS" отправлена`);
    };
  
    return (
        <button className={islink==='Online' && revers ? 'BtnRevers active'  : 'BtnRevers'} onClick={handleClick}> 
        <img  src={rotationRevers} alt="Result" />
        </button>
    );
}