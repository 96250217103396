import { useState ,useEffect} from 'react'
import {useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDms, addimei } from '../../redux/actions';
import ImeiSummary from '../ImeiSummary/ImeiSummary';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';
import magnifier from "../../img/magnifier.svg"
import plus from "../../img/plus.png"
import './ImeiList.css';


export default function ImeiList() {
    const navigate = useNavigate();
    const token = localStorage.getItem('token'); // Предположим, что токен хранится в localStorage
    const [input,setInput] =useState('');
    const dispatch = useDispatch();
    const [isLoading,setIsLoading]=useState(false);
    const role=useSelector((state) => state.userdata?.role);

    useEffect(() => {
        const fetchGetDms = async () => {
            try { await dispatch(getDms());
            } catch (error) { console.error('Ошибка при получении данных:', error);}
            };
            fetchGetDms();
    }, [dispatch]);

    useEffect(() => {
        if (!token) {navigate('/');}
        setIsLoading(true);
        if (role === 1) {
            setIsLoading(false);
            } else if (role) {
            navigate('/');
            }
    }, [role]);

    const data = useSelector((state) => state.dm?.data);
    const [modal, setModal] = useState(false);
    const [inputvalue, setInputvalue] = useState(111111111111111);
    function onClickWinOpen() {
        setInputvalue('');
        setModal(true);
    }
    function onClickSave (inputvalue) {
      setModal(false);
      dispatch(addimei(inputvalue));
    }

    return(
    <> 
        <div className='ImeiList'> 
            <div className='ImeiListHeader'> 
                <div className='ImeiListName'> 
                    <h2> Всего IMEI<span>{data.length}</span></h2> 
                    <div className='ImeiListAdd'>
                        <button className="ImeilistBtn" onClick={()=>onClickWinOpen()} > 
                            <img src={plus} alt="Result" className='ImeiListQuestImgAdd' />
                        Создать IMEI</button>
                    </div>
                </div>
                <div className='ImeiListQuest'> 
                    <input type='text' className='ImeiListInput' placeholder='Введите название для поиска'
                        value={input}
                        onChange={(e)=>setInput(e.target.value)}
                    /> 
                    <img src={magnifier} alt="Result" className='ImeiListQuestImgMag'/>
                </div>
            </div> 

            <div>
                {!isLoading && 
                <div className='ImeiListMain'>
                
                    {data && data.filter((item) =>
                            item.imei_name.toLowerCase().includes(input.toLowerCase())
                            ).map(item=>{
                            return(<ImeiSummary item={item} key={item.id} />)})}
                </div>
                }
            </div>
        </div>
        <Modal open={modal}>     
                    <div className='modalHeader'>
                        <h3>Введите IMEI</h3>
                        <svg  onClick={()=>setModal(false)} height="30" viewBox="0 0 200 200" width="30">    
                            <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                        </svg> 
                    </div>
                    <div className='modalInfo'> 
                        <label className='labelInfo'></label>
                        <h4 className='labelInfoQuet'>
                        Желаете добавить новый IMEI {inputvalue} ? </h4>
                    </div>
                        <div className='contentControl'>
                        <input type='number' className='input' value={inputvalue} onChange={e=>setInputvalue(e.target.value)} />
                            <div className='modalButton' >
                                <Button onClick={() => setModal(false)}>Отмена</Button>
                                <Button onClick={()=>onClickSave(inputvalue)}>Сохранить</Button>
                            </div>
                        </div>
        </Modal>  
    </>
    )
}