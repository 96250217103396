import FooterNav from '../components/UI/FooterNav/FooterNav';


export default function Users() {

    return(
        <>
             <div>
                <h2> Список Пользователей</h2>
                <p>Добавить и удалить Пользователя</p>
             </div>

             <FooterNav />
        </>
    )
}