import './DmSignal.css';

export default function DmSignal({signalValue, param_last_date}) {
  const OFFLINE_THRESHOLD_MINUTES = 5; // Порог оффлайна в минутах
  const serverTime = new Date(param_last_date);
  const browserTime = new Date();
  const diffInMilliseconds = Math.abs(browserTime - serverTime);
  const diffInMinutes = Math.floor(diffInMilliseconds/(1000*60));
  let islink;
  if (diffInMinutes > OFFLINE_THRESHOLD_MINUTES) {
      islink = 'Offline';
  } else if (diffInMinutes > 2) {
      islink = 'Degraded'; // минуту не была на связи
  } else {
      islink = 'Online';
  }

let signalLevel=0;
let color = '#aba0a6cc'; // Цвет по умолчанию (серый для оффлайна)

    const bars = [25, 50, 75, 100]; // Проценты высоты столбцов
    const signalLevels = [
        { min: 1, max: 9, level: 1, color: 'red' },
        { min: 10, max: 14, level: 2, color: '#f0b410' },
        { min: 15, max: 25, level: 3, color: '#f0b410' },
        { min: 26, max: 32, level: 4, color: '#0ca32a' }
      ];

      const currentSignal = signalLevels.find(({ min, max }) => signalValue >= min && signalValue <= max);
      if (currentSignal) {
        signalLevel = currentSignal.level;
        color = currentSignal.color;
      } 

    return(   
        <div className="signal">
            <div className='signalValue'>    
        {bars.map((height, index) => (
          <div
            key={index}
            className={`bar ${
            islink === 'Offline' 
                ? 'offline'
                : islink === 'Degraded'
                ? 'degraded'    
                : `level-${signalLevel}`
             }`}
            style={{
              height: `${signalLevel >= index + 1 ? height : 0}%`,
            }}
          ></div>
        ))}
            </div>
        </div>
    )
}