import authReducer from './authReducer';
import dmReducer from './dmReducer';
import userReducer from './userReducer';
import dmimeiReducer from './dmImeiReducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    auth: authReducer,
    dm: dmReducer,
    dmimei: dmimeiReducer,
    userdata: userReducer
  });
  
  export default rootReducer;