import { useState ,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getDmImei, writesettings, btnSelect_send_sms } from '../../../../redux/actions';
import Button from "../../../UI/Button/Button";
import Modal from '../../../UI/Modal/Modal'
import '../DmConfig.css'


export default function DmSufety() {

    // Получаем текущее значение параметра из Redux
    const parameter = useSelector((state) => state.dmimei.data[0]);
    const dispatch = useDispatch(); 

    function SelectSendSms() {
    useEffect(() => {
        setValue([(parameter?.config_id*1-1)]);
    }, [parameter]);
    const arOptions = ['Нет', 'Да'];
    const [value, setValue] = useState();//вставить приходящий параметр  
    const options = arOptions.map((text, index) => {
        return <option key={index} value={index}>{text}</option>;          
    });
    function onClickSaveSendSMS(e) {
        setValue(e.target.value);
        console.log('Type '+ e.target.value);  
        if(parameter?.config_id!== (e.target.value*1+1)){
        dispatch(btnSelect_send_sms(parameter?.imei,(e.target.value*1+1)))
        }
    }
    return (
        <div className='ParamInput'>
            <label>Отправлять SMS при изменении состояния машины:</label>
            <select className='DmSelect' value={value} 
             onChange={onClickSaveSendSMS}>{options}</select> 
         </div> 
    )
 }

     useEffect(() => {
        if (parameter?.cmd11) {setNumber(8+parameter?.cmd11);} else {setNumber('...');};
    }, [parameter]);
    const [number, setNumber] = useState();

 function NumberInput() {
    const [modal, setModal] = useState(false)
    const [inputvalue, setInputvalue] = useState();
    function onClickNumberOpen() {
        setInputvalue('');
        setModal(true);
    }
    function onClickNumberSave(inputvalue) {
        const num = 40; 
        setModal(false);
        setInputvalue('');
        if (inputvalue.length===11) {
            setNumber(inputvalue);
        dispatch(writesettings(parameter?.imei, inputvalue.slice(1), num))
        .then(() => {
            // Обновляем данные после успешного сохранения
            return dispatch(getDmImei(parameter?.imei));
        })
        .then(() => {
            // Теперь parameter должно обновиться автоматически через useEffect
            console.log("Данные обновлены");
        })
        .catch((error) => {
            console.error("Ошибка при сохранении или обновлении данных: ", error);
        });

        } else {alert(`Номер не сохранен. Проверьте еще раз.`)}
             
    }
    return( 
        <div className='ParamInput'>
            <label>Номер телефона для оповещения:</label>
            <Button  onClick={()=>onClickNumberOpen()}>
                <span className='buttonInput'>{number}</span>
            </Button> 
                <Modal open={modal}>
                     <div className='modalHeader'>
                        <h3>Введите новое значение</h3>
                        <svg  onClick={()=>setModal(false)} height="30" viewBox="0 0 200 200" width="30">    
                            <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                        </svg> 
                    </div>
                    <label className='labelInfo'>Номер телефона для оповещения:</label>
                    <h4 className='labelInfoQuet'>Желаете установить {inputvalue} ? </h4>
                    <div className='contentControl'>
                        <input type='text' className='input' value={inputvalue} onChange={e=>setInputvalue(e.target.value)} />

                     <div className='modalButton' >
                                <Button onClick={() => setModal(false)}>Отмена</Button>
                                <Button onClick={()=>onClickNumberSave(inputvalue)}>Сохранить</Button>
                     </div>
                     </div>
                </Modal>
         </div> 
        ) 
}
    return(
        <div className='cardDm'>
            <h3>Целостность</h3> 
            <NumberInput />
            <div className='selectSms'>  
                <SelectSendSms /> 
            </div> 
        </div>
    )
}