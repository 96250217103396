import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { writesettings, getDmImei } from '../../../../redux/actions';
import Button from "../../../UI/Button/Button";
import Modal from '../../../UI/Modal/Modal'
import measuring from '../../../../img/measuring.svg';
import './BtnHydro.css'


export default function BtnHydro() {
    const parameter = useSelector((state) => state.dmimei?.data[0]);
    const dispatch = useDispatch();

    // Используем useEffect для обновления состояния компонента при изменении Redux-состояния
    useEffect(() => {
        setRainfall(parameter?.QNorma);
    }, [parameter]); // Зависимость от parameter

    const [modal, setModal] = useState(false)
    const [rainfall, setRainfall] = useState()
    const [inputvalue, setInputvalue] = useState();
    const [hasError, setHasError] = useState(false);

    function onClickWinOpen() {
            setInputvalue('');
            setModal(true);
            setHasError(false);
    }
    function onClickSave(inputvalue) {
        if (!inputvalue) {setHasError(true)} else { 
        setInputvalue('');
        setRainfall(inputvalue);
        setModal(false);
        setHasError(false);
        let qnorma=(inputvalue.replace(',', '.')*1).toFixed(2);
        if(qnorma<(parameter?.QSpeed100)*1){qnorma=parameter?.QSpeed100}
        const timemove=((qnorma*parameter?.TimeSpeed100)/parameter?.QSpeed100).toFixed(2);
        const speed=((parameter?.QSpeed100/qnorma)*100).toFixed(2);
        dispatch(writesettings(parameter?.imei, speed, 0))
        dispatch(writesettings(parameter?.imei, timemove, 2))
        dispatch(writesettings(parameter?.imei, qnorma, 1))
        dispatch(writesettings(parameter?.imei, Math.round(speed).toString(), 6))
        .then(() => {dispatch(getDmImei(parameter?.imei))})
        .then(() => {console.log("Данные обновлены")})
        .catch((error) => {console.error("Ошибка при сохранении или обновлении данных: ", error);});
     }
    }

    return(
        <div className='CalcBtn'>
             <button className='CalcHydro'  onClick={onClickWinOpen}> 
             <img  src={measuring} alt="Result" />
             {rainfall} мм</button>

             <Modal open={modal}>
            <div className='modalHeader'>
                        <h3>Введите новое значение</h3>
                        <svg  onClick={()=>setModal(false)} height="30" viewBox="0 0 200 200" width="30">    
                            <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                        </svg> 
                    </div>
                    <div className='modalInfo'> 
                        <label className='labelInfo'>Норма вылива, мм</label>
                        <h4 className='labelInfoQuet'>{hasError ? ("Передумали?") : ("Изволите установить "+`${inputvalue}`+'?')}</h4>
                    </div>
                        <div className='contentControl'>
                        <input type='text' className='input' value={inputvalue} onChange={e=>setInputvalue(e.target.value)}
                        style={{border: hasError ? '1px solid red' : null}} />
                            <div className='modalButton' >
                                <Button onClick={() => setModal(false)}>Отмена</Button>
                                <Button onClick={()=>onClickSave(inputvalue)}>Сохранить</Button>
                            </div>
                        </div>                     
            </Modal> 
        </div>
    )
}