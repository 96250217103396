import { useState ,useEffect} from 'react'
import { writesettings, getDmImei, btnReducerGeoLink } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import teach from '../../../../img/palochka.png' 
import '../DmConfig.css'
import Button from "../../../UI/Button/Button";
import Modal from '../../../UI/Modal/Modal'



export default function DmGNSS() {
    // Получаем текущее значение параметра из Redux
    const parameter = useSelector((state) => state.dmimei.data[0]);

    const dispatch = useDispatch();

     // Используем useEffect для обновления состояния компонента при изменении Redux-состояния
     useEffect(() => {
        setGnssParam([parameter?.OffsetNulN || '...', parameter?.PositionCShr || '...', parameter?.PositionCDl || '...']);
    }, [parameter]); // Зависимость от parameter

    const [gnssParam, setGnssParam] = useState([]);
    const nameParam = [
        "Смещение 0\u00b0 относительно севера, град",
        "Координата центра, широта",
        "Координата центра, долгота",
        ]
    function GNSSparametr() {
        const [modal, setModal] = useState(false)
        const [param, setParam] = useState()
        const [inputvalue, setInputvalue] = useState();
        const [hasError, setHasError] = useState(false);
        function onClickWinOpen(name, index) {
            setInputvalue(gnssParam[index]);
            setModal(true);
            setParam(name);
            setHasError(false);
        }
        function onClickSave(num, inputvalue) {      
        if (!inputvalue) {setHasError(true)} else {
        setInputvalue('');
        setModal(false);   
        setHasError(false); 
        if (num===0) { //ограничим ввод смещения от 0 до 359 и округлим до целого
            let offset = Math.round(inputvalue.replace(',', '.')*1);
            if (offset<=0) {offset=0}
            if (offset>359) {offset=359}
            inputvalue=offset*1;
        }
        if (num===1 || num===2) {
            let coordinata=inputvalue.replace(',', '.');
            if(!isNaN(coordinata) && coordinata.trim() !== '') {// проверим что то что ввели это число
                inputvalue=coordinata.toString();  
            } else {inputvalue=gnssParam[num]}          
        }
        dispatch(writesettings(parameter?.imei, inputvalue, 20+num))
        .then(() => {
            // Обновляем данные после успешного сохранения
            return dispatch(getDmImei(parameter?.imei));
        })
        .then(() => {
            // Теперь parameter должно обновиться автоматически через useEffect
            console.log("Данные обновлены");
        })
        .catch((error) => {
            console.error("Ошибка при сохранении или обновлении данных: ", error);
        });
        }
      }
        return (  
            nameParam.map((text, index)=>(
                <div className='ParamInput' key={index}> 
                <label>{text}</label> 
                <Button  onClick={()=>onClickWinOpen(text, index)}>
                <span className='buttonInput'>{gnssParam[index]}</span>
                </Button> 
                {param===text &&
                <Modal open={modal}>     
                    <div className='modalHeader'>
                        <h3>Введите новое значение</h3>
                        <svg  onClick={()=>setModal(false)} height="30" viewBox="0 0 200 200" width="30">    
                            <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
                        </svg> 
                    </div>
                    <div className='modalInfo'> 
                        <label className='labelInfo'>{text}</label>
                        <h4 className='labelInfoQuet'>{hasError ? ("Передумали?") : ("Желаете установить "+`${inputvalue}`+'?')}</h4>
                        <h4 className='labelInfoQuet'>{index===0 ? ("Смещение нуля может принимать значение от 1\u00b0 до 359\u00b0. С шагом в 1\u00b0.") : (null)}</h4>
                    </div>
                        <div className='contentControl'>
                        <input type='text' className='input' value={inputvalue} onChange={e=>setInputvalue(e.target.value)}
                        style={{border: hasError ? '1px solid red' : null}} />
                            <div className='modalButton' >
                                <Button onClick={() => setModal(false)}>Отмена</Button>
                                <Button onClick={()=>onClickSave(index,inputvalue)}>Сохранить</Button>
                            </div>
                        </div>
                </Modal>}
                </div>
            ))
        )  
    }
    function hendleClick() {
        console.log('Teach GEOLINK')
        dispatch(btnReducerGeoLink(parameter?.imei));
    }
    return(
        <div className='cardDm'>
         <h3>GNSS</h3>
            <div className='DmGnssConfig'>
                <GNSSparametr />
            </div>   
                <div className='GeoLink'>
                    <label>Обучение Geo-Link</label>
                    <div className='GeoLinkBtn'>    
                        <Button className='Btn' onClick={hendleClick}>
                            <img src={teach} alt="Result" />
                        </Button>
                    </div>
                </div>
        </div>
    )
}