import { useState ,useEffect} from 'react'
import  './PressureSensor.css';
// import {useSelector } from 'react-redux';


export default function PressureSensor({adc, pmax, islink}) {
    
    const [pressure, setPressure] = useState(0);
    // Постоянные значения для расчёта
    const minADC = 800;  // Минимальное значение АЦП (соответствует 4 мА)
    const maxADC = 3000; // Максимальное значение АЦП (соответствует 20 мА)
    const Pmin = 0;      // Минимальное давление (4 мА = 0 Бар)

    useEffect(() => {
        // Проверка наличия необходимых данных
        if (adc && pmax) {
            // Вычисление давления по формуле
            const calculatedPressure =
                Pmin + (pmax - Pmin) * (adc - minADC) / (maxADC - minADC);

            // Обновление состояния давления
            setPressure(calculatedPressure > 0 ? calculatedPressure : 0); // Не допускаем отрицательных значений
        } else {
            // Если данных нет, сброс давления
            setPressure(0);
        }
       
    }, [adc, pmax]); // Пересчитывать давление при изменении adc или pmax

// Состояние для хранения переменной
//пока АЦП=800 -минимум 4мА или 0 Бар. АЦП=3000-максимум 20мА или Pmax(предел датчика) 
// P=Pmin+(Pmax-Pmin)*(АЦП-minАЦП/maxАЦП-minАЦП)

    return(
       
        <div className='mapSensor'>
            {pressure > 0.1 && islink==='Online' ? (
                <div>P: {pressure.toFixed(2)} Бар</div> // Отображение давления с двумя знаками после запятой
            ) : (
                <div>P: ... </div> // Альтернативный вывод
            )}                  
        </div>
    )
}