import React from 'react';
import { useDispatch } from 'react-redux';
import { btnReducerForward } from '../../../../redux/actions';
import rotationForward from '../../../../img/forward.png'
import './BtnForward.css'

export default function BtnForward({islink, state, imei}) {
  
  const dispatch = useDispatch();
  const checkBit = (num, bitPosition) => {
    return (num & (1 << bitPosition)) !== 0;
  };

  const forward=checkBit(state, 6);
   
  const handleClick = () => {
      dispatch(btnReducerForward(imei));
      alert(`Команда "FORWARD" отправлена`);
    };
  
    return (
        <button className={islink==='Online' && forward ? 'BtnForward active'  : 'BtnForward'} onClick={handleClick}>
        <img  src={rotationForward} alt="Result" />
        </button>
    );
}




