import { useState, useEffect } from 'react'
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkReducerWater, checkReducerNoWater  } from '../../../redux/actions';
import BtnForward from '../BtnGrup/BtnForward/BtnForward';
import BtnRevers from '../BtnGrup/BtnRevers/BtnRevers';
import BtnStop from '../BtnGrup/BtnStop/BtnStop';
import BtnPersent from '../BtnCalc/BtnPersent/BtnPersent';
import BtnHydro from '../BtnCalc/BtnHydro/BtnHydro';
import BtnTime from '../BtnCalc/BtnTime/BtnTime';
import Checkbox from '../../UI/Checkbox/Checkbox';
import water from '../../../img/water-tap.png';
import './DmCommands.css';


export default function DmCommands() {
    const parameter = useSelector((state) => state.dmimei?.data[0]);
    const dispatch = useDispatch();
    const [dmcmd, setDmcmd ] = useState({
        param_last_date: 0,
        state: 0,
        imei: '...',
    });
      //проверка входных данных
      useEffect(() => {
        setDmcmd({
        param_last_date: parameter?.param_last_date || 0,
        state: parameter?.param4 || 0,
        imei: parameter?.imei || '...',
        })   
    },[parameter]);

    const OFFLINE_THRESHOLD_MINUTES = 5; // Порог оффлайна в минутах
    const serverTime = new Date(dmcmd.param_last_date);
    const browserTime = new Date();
    const diffInMilliseconds = Math.abs(browserTime - serverTime);
    const diffInMinutes = Math.floor(diffInMilliseconds/(1000*60));
    const islink = diffInMinutes > OFFLINE_THRESHOLD_MINUTES ? 'Offline' : 'Online';


    const checkBit = (num, bitPosition) => {
        return (num & (1 << bitPosition)) !== 0;
      };
    const [checked, setChecked] = useState();
    const [mode, setMode] = useState();
    
    useEffect(() => {  
        if (checkBit(parameter?.param4, 3)) { //режим "без воды", круг зелёный, слайсер влево
            (setMode(true));
            (setChecked(true));
        } else {                       //режим "с водой", круг синий, слайсер вправо
            (setMode(false));
            (setChecked(false));
        }
        }, [parameter]);   

    function handleCheckboxChange(event) {
      setChecked(event.target.checked);
      console.log('CHECK'+checked);
      setMode(!mode);
      {mode ? dispatch(checkReducerWater(dmcmd.imei)) : dispatch(checkReducerNoWater(dmcmd.imei))}//mode? `cmd2`=0 : `cmd2`=2
  }
    return(
        <div className='dmDetalsCommand'> 
            <div className='commandHeader'>
                <h4>Управление</h4> 
            </div>
            <div className='commandBlock'>
                <BtnRevers islink={islink} state={dmcmd.state} imei={dmcmd.imei} />
                <BtnStop imei={dmcmd.imei} />
                <BtnForward islink={islink} state={dmcmd.state} imei={dmcmd.imei} /> 
                <div className='commandBlockSwitch'> 
                    <img  src={water} alt="Result" />
                    <Checkbox handleCheckboxChange={handleCheckboxChange}  checked={checked} id="checkbox1" /> 
                </div> 
            </div>
            <div className='commandRegulation'>
            <BtnPersent />
            <BtnHydro />
            <BtnTime />                     
            </div>
        </div>
    )
}